import { Global } from '@iupics-manager/models/global-var';
import moment from 'moment';

export namespace DateUtils {
  export function setLocale(language: string) {
    if(language)
        //#START CUSTO-SAMVAZ
      if (language === 'fr_FR') {
        moment.locale('fr_CH');
      } else {
        moment.locale(language);
      }
    //#END CUSTO-SAMVAZ
  }

  export function simpleFormatStr(date: moment.MomentInput, pattern: string = Global.dateFormat) {
    return moment(date).format(pattern);
  }

  export function formatStr(date: moment.MomentInput, pattern: string = Global.dateFormat): string {
    const str = simpleFormatStr(date, pattern);
    return str.substring(0, 26) + '' + str.substring(27, str.length);
  }

  export function formatLocaleStr(date: moment.MomentInput): string {
    return moment(date).format('L') /* .slice(0, 10) */;
  }

  export function formatLongLocaleStr(date: moment.MomentInput): string {
    return moment(date).format('L LT').slice(0, 16);
  }

  export function toDate(value: moment.MomentInput, fromFormat?: string) {
    return moment(value, fromFormat).toDate();
  }

  export function valueOf(value: moment.MomentInput) {
    return moment(value).valueOf();
  }

  export function isDate(value: any): value is Date {
    return value instanceof Date;
  }

  export function toDateSpecific(value: any, needTime: boolean = false): Date {
    if (!value) {
      return value;
    }

    let tmpValue = new Date(value);
    if (!DateUtils.isDate(value)) {
      tmpValue = DateUtils.toDate(value);
    }

    if (!DateUtils.isDate(tmpValue)) {
      return null;
    }

    if (needTime === false) {
      tmpValue.setHours(0);
      tmpValue.setMinutes(0);
    }
    tmpValue.setSeconds(0);
    tmpValue.setMilliseconds(0);

    return tmpValue;
  }

  export function isSame(
    date1: moment.MomentInput,
    date2: moment.MomentInput,
    granularity?: moment.unitOfTime.StartOf
  ) {
    return moment(date1).isSame(date2, granularity);
  }

  export function compareDateValue(value1: moment.MomentInput, value2: moment.MomentInput) {
    const value1Date = toDateSpecific(value1);
    const value2Date = toDateSpecific(value2);

    if (value2Date && value1Date) {
      return new Date(value1Date).getTime() !== new Date(value2Date).getTime();
    }

    return !!value1Date || !!value2Date;
  }

  function getMoment(date: moment.MomentInput, format?: string) {
    let m: moment.Moment;
    if (format) {
      m = moment(date);
    } else {
      m = moment(date, format);
    }

    return m;
  }

  export function add(
    date: moment.MomentInput,
    add: moment.DurationInputArg1,
    unit: moment.DurationInputArg2,
    format?: string
  ) {
    return getMoment(date, format).add(add, unit).toDate();
  }

  export function subtract(
    date: moment.MomentInput,
    add: moment.DurationInputArg1,
    unit: moment.DurationInputArg2,
    format?: string
  ) {
    return getMoment(date, format).subtract(add, unit).toDate();
  }

  export function diff(date1: moment.MomentInput, date2: moment.MomentInput, unit?: moment.DurationInputArg2) {
    return moment(date1).diff(moment(date2), unit);
  }
}
